/* You can add global styles to this file, and also import other style files */
@import "~primeng/resources/themes/saga-blue/theme.css";
@import "~primeng/resources/primeng.min.css";
@import "~primeicons/primeicons.css";
@import "~primeflex/primeflex.css";
@import "./assets/styles/custom-styles.scss";

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
