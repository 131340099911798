.cmn-header-style {
    font-family: Roboto, sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
}

.cmn-logout-style {
    font-family: Roboto, sans-serif;
    font-size: 11px;
    text-transform: none;
    font-weight: 500;
}


.cmn-breadcrumb-style {
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
}

.cmn-div-padding {
    padding: 0.5em;
}

.cmn-label-font-style {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
}

.cmn-input-font-style {
    font-size: 12px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
}

.cmn-button-style {
    font-family: Roboto, sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
}

.cmn-tabmenu-style {
    font-size: 13px;
    font-family: Roboto, sans-serif;
}

.cmn-table-header-style {
    font-size: 13px;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
}

.cmn-table-data-style {
    font-size: 12px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
}

.cmn-validation-status-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
    text-transform: uppercase;
}

.cmn-validation-status-Mismatch {
    background-color: #ffcdd2;
    color: #c63737;
}

.cmn-mandatory-field-style {
    border-left: 3px solid #eb161e;
    padding-left: 6px;
}

.cmn-validation-status-NotVerified {
    background-color: #ffd8b2;
    color: #805b36;
}

.cmn-validation-status-Match {
    background-color: #c8e6c9;
    color: #256029;
}

.cmn-validation-error {
    margin-top: 3px;
    margin-bottom: 3px;
    color: red;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 13px;
}

.cmn-msg-style {
    font-size: 13px;
    font-family: Roboto, sans-serif;
    font-weight: 600;
}